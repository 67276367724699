import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import CommingSoon from '../components/elements/CommingSoon';
import Home from '../pages/home/Home';
import AllStories from '../pages/stories/AllStories';
import FAQs from '../pages/faqs/FAQs';
import StaticPage from '../pages/static-page/StaticPage';
import AboutUsPage from '../pages/static-page/AboutUsPage';
import TreatmentsPage from '../pages/static-page/TreatmentsPage';

import Auth from '../pages/auth/Auth';

// import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// handle the all routes
function RoutesList() {

  return (
    <BrowserRouter>
      <Switch>

        {/* <Route exact path="/" component={CommingSoon} /> */}

        <PublicRoute path="/auth" component={Auth} />
        <Route exact path="/" component={Home} />
        <Route path="/about-us" component={AboutUsPage} />
        <Route path="/treatments" component={TreatmentsPage} />
        <Route path="/privacy-policy" component={StaticPage} />
        <Route path="/terms-&-conditions" component={StaticPage} />
        <Route path="/data-protection-policy" component={StaticPage} />
        <Route path="/success-stories" component={AllStories} />
        <Route path="/faqs" component={FAQs} />

      </Switch>
    </BrowserRouter>
  )
}

export default RoutesList;