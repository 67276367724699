import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import BookNowModal from '../auth/Auth';

function Weightloss(props) {

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {

    }, []);
    
    const openBookingModal = () => {
        setTimeout(() => {
            setOpenModal(false);
            setOpenModal(true);
        }, 100);
    }

    return (

        <>

            {openModal && <BookNowModal status={openModal} />}

            <section className="section" id="call-to-action">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="cta-content">
                                <h2>Start <em>Losing Weight</em> Today!</h2>
                                <p>{props.text}</p>
                                <div className="main-button scroll-to-section">
                                    <Link to={'#'} onClick={openBookingModal}>Book Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    );
}

export default Weightloss;