import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

// set the token from the session storage
export const setToken = (token) => {
    sessionStorage.setItem('token', token);
}

// set user from the session storage
export const setUser = (user) => {
    sessionStorage.setItem('user', JSON.stringify(user));
}

// return the token from the session storage
export const getToken = () => {
    return sessionStorage.getItem('token') || null;
}

// return the user data from the session storage
export const getUser = () => {

    let userStr = sessionStorage.getItem('user');

    if (userStr) {
        return JSON.parse(userStr);
    } else {
        return null;
    }
}

// set cookie
export const setCookie = (cookie) => {
    sessionStorage.setItem('cookie', cookie);
}

// get cookie
export const getCookie = () => {
    return sessionStorage.getItem('cookie') || false;
}

// clear the session storage
export const clearStorage = () => {
    sessionStorage.clear();
}

export const loadIframeOnTritmentPage = () => {
    window.callTheIframeOnTritmentPage();
}

export const loadDashboardCharts = () => {
    window.callThejQueryDashboardCharts();
}

export const loadDataTable = () => {
    window.callThejQueryDataTable();
}

export const confirmBox = (message, callback) => {

    alertify.confirm(
        "Confirmation",
        message,
        function () {
            callback(true)
        },
        function () {
            callback(false)
        });
}

export const promptBox = (message, callback) => {
    alertify.prompt(
        "Confirmation",
        message,
        function (evt, value) {
            callback(true, value)
        },
        function () {
            callback(false)
        });
}

export const successMessage = (message) => {

    alertify.success(message);
}

export const errorMessage = (message) => {

    alertify.error(message);
}

export const setLoader = (status) => {
    if (status) {
        document.getElementById('js-preloader').classList.remove('loaded');
    } else {
        document.getElementById('js-preloader').classList.add('loaded');
    }
}

export const home = () => {

    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
}

export const gotoChoseProgram = () => {

    setTimeout(() => {
        window.scrollTo(0, (document.body.clientHeight/100*15));
    }, 100);
}

export const gotoBMI = () => {

    setTimeout(() => {
        window.scrollTo(0, (document.body.clientHeight/100*58));
    }, 100);
}

export const contactUS = () => {

    setTimeout(() => {
        // window.scrollTo(0, document.body.clientHeight); // 100%
        window.scrollTo(0, (document.body.clientHeight/100*68));
    }, 100);
}

export const counterInitialValue = () => {

    // let min = 11426.477;
    // let max = 36311.536;

    // let first = Math.floor(Math.random() * (36311 - 11426 + 1)) + 11426;
    // let second = Math.floor(Math.random() * (999 - 1 + 100)) + 100;

    let first = 986211;
    let second = 631;

    return [first, second];

}

export const showHideBodyline = (index) => {

    setTimeout(() => {

        try {
            let articles = document.getElementById('body-line-content').childNodes.length;

            for (let i = 0; i < articles; i++) {
                document.getElementById('tabs-li-' + i).classList.remove('ui-tabs-active');
                document.getElementById('tabs-' + i).classList.add('hide');
            }

            document.getElementById('tabs-li-' + index).classList.add('ui-tabs-active');
            document.getElementById('tabs-' + index).classList.remove('hide');
        } catch(err) {
            
        }
    }, 100);
}

export const toggalMobileMenu = () => {

    document.getElementById('menu-trigger').classList.toggle('active');

    if(document.getElementById('menu-trigger').classList.contains('active')) {
        document.getElementById("nav").style = "display: block;"
    } else {
        document.getElementById("nav").style = "display: none;"
    }
}

export const toggalUnitType = () => {

    document.getElementById('height').value = null;
    document.getElementById('weight').value = null;
}
