import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as CustomValidators from '../../providers/shared/validator';
import * as GlobalProvider from '../../providers/globals/globals';

function BMI_Calculator(props) {

    const [formData, setFormData] = useState({
        calculation_unit: 'metric',
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [calculationHeightText, setCalculationHeightText] = useState('Height (cm)');
    const [calculationWeightText, setCalculationWeightText] = useState('Weight (kg)');
    const [calculationResult, setCalculationResult] = useState(null);

    const handleChange = (event) => {

        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        if (['height', 'weight'].includes(name)) {

            formData[name] = parseInt(value);
        }
        else if (name === 'calculation_unit') {

            formData[name] = value;

            GlobalProvider.toggalUnitType();

            formData.height = null;
            formData.weight = null;

            setCalculationHeightText((formData.calculation_unit === 'metric') ? 'Height (cm)' : 'Height (in)');
            setCalculationWeightText((formData.calculation_unit === 'metric') ? 'Weight (kg)' : 'Weight (lb)');
            setCalculationResult(null);
        }
        else {
            formData[name] = value;
        }

        setFormData(formData);
    }

    const validateLoginForm = (e) => {

        let errors = {};

        if (CustomValidators.isEmpty(formData.height)) {
            errors.height = "Height can't be blank";
        }
        else if (!CustomValidators.isMoreThenZero(formData.height)) {
            errors.height = "Height should be more then 0";
        }
        else if (!CustomValidators.integerOnly(formData.height)) {
            errors.height = "Please provide a valid number";
        }

        if (CustomValidators.isEmpty(formData.weight)) {
            errors.weight = "Weight can't be blank";
        }
        else if (!CustomValidators.isMoreThenZero(formData.weight)) {
            errors.weight = "Weight should be more then 0";
        }
        else if (!CustomValidators.integerOnly(formData.weight)) {
            errors.weight = "Please provide a valid number";
        }

        if (CustomValidators.isEmpty(errors)) {
            return null;
        } else {
            return errors;
        }
    }

    const calculateBMI = (e) => {

        e.preventDefault();

        setErrors({});
        setLoading(true);

        let errors = validateLoginForm();

        if (!errors) {

            setErrors({});
            setLoading(false);

            let bmi = computeBMI(formData.calculation_unit, formData.height, formData.weight);

            setCalculationResult('<span>Your BMI is: ' + bmi.toFixed(1) + '</span>');

        } else {
            setErrors(errors);
            setLoading(false);
        }
    }

    const computeBMI = (units_type, height, weight) => {

        if (units_type === 'metric') {
            height = parseFloat(height) / 100;
            weight = parseFloat(weight);
        }
        else {
            height = parseFloat(height) * 2.54 / 100;
            weight = parseFloat(weight) / 2.20462;
        }

        let output = (weight / height / height);

        return output;
    }

    return (

        <section className="section margin-top-50" id="bmi-calculator-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="bmi-content">

                            <h2><em>BMI</em> Calculator</h2>

                            <p>{props.text}</p>

                            <div className="col-lg-12 col-md-6 col-xs-12">

                                <div className="row bmi-type-box">
                                    <div className="col-md-3 col-sm-12 margin-bot-30">
                                        &nbsp;
                                    </div>
                                    <div className="col-md-3 col-sm-12 margin-bot-30">
                                        <fieldset>
                                            <input name="calculation_unit" type="radio" value="metric" defaultChecked={formData.calculation_unit === "metric"} className="form-control" onClick={handleChange} />
                                            <label>Metric</label>
                                        </fieldset>
                                    </div>
                                    <div className="col-md-3 col-sm-12 margin-bot-30">
                                        <fieldset>
                                            <input name="calculation_unit" type="radio" value="imperial" defaultChecked={formData.calculation_unit === "imperial"} className="form-control" onClick={handleChange} />
                                            <label>Imperial</label>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 margin-bot-30">
                                        &nbsp;
                                    </div>
                                    <div className="col-md-3 col-sm-12 margin-bot-30 padding-bot-10">
                                        <fieldset>
                                            <input name="height" id="height" type="number"
                                                min="0" max="200" className="form-control"
                                                placeholder={calculationHeightText}
                                                defaultValue={formData.height}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                            />
                                            {errors.height && <span className="error">{errors.height}</span>}
                                        </fieldset>
                                    </div>
                                    <div className="col-md-3 col-sm-12 margin-bot-30">
                                        <fieldset>
                                            <input name="weight" id="weight" type="number"
                                                min="0" max="200" className="form-control"
                                                placeholder={calculationWeightText}
                                                defaultValue={formData.weight}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                            />
                                            {errors.weight && <span className="error">{errors.weight}</span>}
                                        </fieldset>
                                    </div>
                                </div>
                            </div>

                            <br />

                            <div className="main-button scroll-to-section" disabled={loading}>
                                <Link to={''} onClick={calculateBMI}>{loading ? 'Calculating...' : 'Calculate'}</Link>
                            </div>

                            <br />

                            <div className="col-lg-12 col-md-6 col-xs-12">

                                <div className="row">
                                    <div className="col-md-1 col-sm-12 margin-bot-30">
                                        &nbsp;
                                    </div>
                                    <div className="col-md-11 col-sm-12 margin-bot-30 text-left">

                                        <p className="bmi-text" dangerouslySetInnerHTML={{ __html: calculationResult }} />
                                            {/* {calculationResult} */}
                                        {/* </p> */}

                                        <p>-Underweight: BMI is less than 18.5.</p>

                                        <p>-Normal weight: BMI is 18.5 to 24.9.</p>

                                        <p>-Overweight: BMI is 25 to 29.9.</p>

                                        <p>-Obese: BMI is 30 or more.</p>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
            </div>
            </div>
        </section >

    );
}

export default BMI_Calculator;