import React, { useEffect, useState } from "react";
import * as GlobalProvider from '../../providers/globals/globals';
import * as ApisService from "../../providers/apis/apis";
import Header from '../../components/elements/Header';
import Banner from './Banner';
import Program from './Program';
import Bodyline from './Bodyline';
import Weightloss from './Weightloss';
import Stories from './Stories';
import YoutubeVideo from './YoutubeVideo';
import BMI_Calculator from './BMI_Calculator';
import Contact from './Contact';
import Footer from '../../components/elements/Footer';
import InstaFeed from "./InstaFeed";

function Home(props) {

  const [homeData, setHomeData] = useState({});
  const [programs, setPrograms] = useState([]);
  const [bodylines, setBodylines] = useState([]);
  const [stories, setStories] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {

    GlobalProvider.setLoader(true);

    ApisService.getHomeData()
      .then(response => {

        GlobalProvider.setLoader(false);

        if (response.status) {
          setHomeData(response.home);
          setPrograms(response.programs);
          setBodylines(response.bodylines);
          setStories(response.stories);
        } else {
          GlobalProvider.errorMessage(response.message);
        }

      }).catch(error => {
        GlobalProvider.setLoader(false);
        GlobalProvider.errorMessage(error);
      });
  }

  return (

    <>
      <Header activeClass="home" />

      <Banner data={homeData} />
      <Program text={homeData.choose_program_text} list={programs} />
      <Bodyline text={homeData.body_line_works_text} list={bodylines} />
      <Weightloss text={homeData.weight_loss_text} />
      <Stories text={homeData.success_stories_text} list={stories} />
      {/* <YoutubeVideo /> */}
      <BMI_Calculator text={homeData.bmi_text} />
      {/* <InstaFeed/> */}
      <Contact text={homeData.map_text} />
      <Footer />
    </>

  )

}

export default Home;