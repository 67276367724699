import React, { useEffect, useState } from "react";
import * as GlobalProvider from '../../providers/globals/globals';
import * as ApisService from "../../providers/apis/apis";
import Header from '../../components/elements/Header';
import Footer from '../../components/elements/Footer';
import WeightCounter from '../../components/elements/WeightCounter';

function AllStories(props) {

    const [stories, setStories] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const counterStart = () => {
        return GlobalProvider.counterInitialValue();
    }

    const loadData = () => {

        GlobalProvider.setLoader(true);

        ApisService.getStories()
            .then(response => {

                GlobalProvider.setLoader(false);
                
                if (response.status) {
                    setStories(response.data);
                } else {
                    GlobalProvider.errorMessage(response.message);
                }

            }).catch(error => {
                GlobalProvider.setLoader(false);
                GlobalProvider.errorMessage(error);
            });
    }

    return (

        <>
            <Header activeClass="success-stories" />

            <section className="section" id="trainers">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <br /><br />
                            <div className="section-heading">
                                {/* <h2>Success <em>Stories</em></h2> */}
                                {/* <img src="images/line-dec.png" alt="" /> */}
                                {/* <p>&nbsp;</p> */}
                                {/* <WeightCounter start={counterStart()} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {stories.map((item, index) =>
                            <div key={'list-' + index} className="col-lg-4">
                                <div className="trainer-item">
                                    <div className="image-thumb">
                                        <img src={item.image} alt="" />
                                    </div>
                                    <div className="down-content">

                                        {/* <WeightCounter start={counterStart()} /> */}

                                        <span>{item.customer_name}</span>
                                        {/* <h4>{item.title}</h4> */}
                                        <p>{item.message}</p>
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}

                    </div>
                </div>
            </section>

            <Footer />
        </>

    );
}

export default AllStories;