
export const appName = "The Albany Clinic";

export const perPage = 10;

export const videoURL = 'https://api.albanyclinic.com/banners/Chelsea-Slimming-BG-720p-Limited.mp4';

export const bookingURL = 'https://albanyclinic.uk1.cliniko.com/bookings?embedded=true';

export const youtubeVideoURL = 'https://www.youtube.com/embed/EajHxasXsHM';

export const imageURL = 'https://api.albanyclinic.com';

export const baseURL = 'https://api.albanyclinic.com/web';
// export const baseURL = 'http://localhost:3001/web';
