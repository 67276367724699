import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import * as GlobalProvider from '../../providers/globals/globals';
import * as GlobalConfig from '../../providers/globals/config';
import BookNowModal from '../../pages/auth/Auth';

function Header(props) {

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {

    }, []);

    const activeClass = (linkOption) => {

        if (linkOption === props.activeClass) {
            return 'active';
        } else {
            return '';
        }
    }

    const home = () => {
        GlobalProvider.home();
    }

    const gotoBMI = () => {
        GlobalProvider.gotoBMI();
    }

    const contactUS = () => {
        GlobalProvider.contactUS();
    }

    const toggalMobileMenu = () => {
        GlobalProvider.toggalMobileMenu();
    }

    const openBookingModal = () => {
        setTimeout(() => {
            setOpenModal(false);
            setOpenModal(true);
        }, 100);
    }

    return (

        <>

            {openModal && <BookNowModal status={openModal} />}

            <header className="header-area header-sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav">
                                <Link to={'/'} className="logo" title={GlobalConfig.appName} onClick={home}>
                                    <img src="images/logo-new.png" alt={GlobalConfig.appName} height="80" />
                                </Link>
                                <ul className="nav" id="nav">
                                    <li className="scroll-to-section"><Link to={'/'} className={activeClass('home')} onClick={home}>Home</Link></li>
                                    <li className="scroll-to-section"><Link to={'/about-us'} className={activeClass('about-us')}>About Us</Link></li>
                                    <li className="scroll-to-section"><Link to={'/treatments'} className={activeClass('treatments')}>Treatments</Link></li>
                                    <li className="scroll-to-section"><Link to={'/success-stories'} className={activeClass('success-stories')}>Results</Link></li>
                                    <li className="scroll-to-section"><Link to={'/faqs'} className={activeClass('faqs')}>FAQ</Link></li>
                                    <li className="scroll-to-section"><Link to={'/#bmi-calculator-section'} onClick={gotoBMI}>BMI Calculator</Link></li>
                                    <li className="scroll-to-section"><Link to={'/#contact-us'} onClick={contactUS}>Contact Us</Link></li>
                                    <li className="main-button"><Link to={'#'} onClick={openBookingModal}>Book Now</Link></li>
                                </ul>
                                <Link to={'#'} id='menu-trigger' className='menu-trigger' onClick={toggalMobileMenu}>
                                    <span>Menu</span>
                                </Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

        </>

    );
}

export default Header;