import React, { useEffect, useState } from 'react';
import * as GlobalProvider from '../../providers/globals/globals';

function CommingSoon() {

    useEffect(() => {
        GlobalProvider.setLoader(false);
    }, []);

    return (
        <>
            <img src="images/coming-soon.jpg" style={{ width: '100%',  height: '100vh' }} alt="" />
        </>
    );
}

export default CommingSoon;