import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import * as SiteSettings from '../../providers/settings/settings';
import * as GlobalConfig from '../../providers/globals/config';
import * as GlobalProvider from '../../providers/globals/globals';
import * as CustomValidators from '../../providers/shared/validator';
import * as ApisService from "../../providers/apis/apis";
import Cookie from './Cookie';

function Footer(props) {

    const [openCookieModal, setCookieOpenModal] = useState(!GlobalProvider.getCookie());
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

    }, []);

    const handleInputChange = (event) => {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        setFormData({ email: value });
    }

    const validateForm = (e) => {

        let error = null;

        if (CustomValidators.isEmpty(formData.email)) {
            error = "Email can't be blank";
        }
        else if (CustomValidators.isContainWhiteSpace(formData.email)) {
            error = "Email should not contain white spaces";
        }
        else if (!CustomValidators.isLength(formData.email, { gte: 5, lte: 32, trim: true })) {
            error = "Email's length must between 5 to 32";
        }
        else if (!CustomValidators.isEmail(formData.email)) {
            error = "Please enter a valid email";
        }

        if (!error) {
            return null;
        } else {
            return error;
        }
    }

    const subscribe = (e) => {

        e.preventDefault();

        setError(null);
        setLoading(true);

        let error = validateForm();

        if (!error) {

            ApisService.subscribe(formData)
                .then(response => {

                    // console.log('response::::', response)

                    setLoading(false);

                    if (response.status) {

                        setFormData({});
                        document.getElementById("subscribe").reset();
                        GlobalProvider.successMessage(response.message);

                    } else {

                        setError(response.message);
                    }

                }).catch(error => {
                    setError(error);
                    setLoading(false);
                });

        } else {
            setError(error);
            setLoading(false);
        }
    }

    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="subscribe-box">
                                <h3>Sign Up To Newsletter</h3>
                                <form id="subscribe" onSubmit={subscribe} method="true">
                                    <input name="email" autoComplete="off" type="text" placeholder="Email Address" defaultValue={formData.email} onChange={handleInputChange} />
                                    {error && <span className="error">{error}</span>}
                                    <button type="submit" disabled={loading}>{loading ? 'Wait...' : 'Subscribe'}</button>
                                </form>
                            </div>
                            <p>© Copyright {SiteSettings.copyrightYear} {GlobalConfig.appName}. All Rights Reserved</p>
                            <p>
                                <Link to={'/privacy-policy'}>Privacy Policy</Link> &nbsp; | &nbsp;&nbsp;
                                <Link to={'/terms-&-conditions'}>Terms & Conditions</Link> &nbsp; | &nbsp;&nbsp;
                                <Link to={'/data-protection-policy'}>Data Protection Policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                                <img src="images/cqc-logo.png" alt="" />
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

            <Cookie status={openCookieModal} />
        </>
    )
}


export default Footer;