import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import * as GlobalProvider from '../../providers/globals/globals';

function Bodyline(props) {

  useEffect(() => {

    setTimeout(() => {
      GlobalProvider.showHideBodyline(0);
    }, 2000);

  }, [])

  const selectBodyline = (index) => {
    GlobalProvider.showHideBodyline(index);
  }

  return (

    <section className="section" id="our-classes">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h2>We get <em>results</em></h2>
              <img src="images/line-dec.png" alt="" />
              <p>{props.text}</p>
            </div>
          </div>
        </div>

        {/* <Tabs>
            <TabList>
              <Tab>Title 1</Tab>
              <Tab>Title 2</Tab>
            </TabList>

            <TabPanel>
              <h2>Any content 1</h2>
            </TabPanel>
            <TabPanel>
              <h2>Any content 2</h2>
            </TabPanel>
          </Tabs> */}

        <div className="row" id="tabs">
          <div className="col-lg-4">
            <ul>
              {props.list.map((item, index) =>
                <li key={'body-line-li' + index} id={'tabs-li-' + index}>
                  <Link to={''} onClick={() => selectBodyline(index)}>
                    <img src={item.icon} alt="" />
                    {item.title.substring(0, 18) + "..."}
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div className="col-lg-8">
            <section className='tabs-content' id="body-line-content">
              {props.list.map((item, index) =>
                <article key={'body-line-article' + index} id={'tabs-' + index} className="bodyline-article hide">
                  <img src={item.image} alt={item.title} />
                  <h4>{item.title}</h4>
                  <div dangerouslySetInnerHTML={{ __html: item.message }} />
                </article>
              )}
            </section>
          </div>
        </div>
      </div >
    </section >

  );
}

export default Bodyline;