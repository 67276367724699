import axios from 'axios';
import * as GlobalProvider from '../globals/globals';
import * as GlobalConfig from '../globals/config';

axios.defaults.baseURL = GlobalConfig.baseURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

function updateHeaders() {
    let token = GlobalProvider.getToken();
    if (token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    }
}

function readError(error) {
    // console.log('error:::', error)

    let message;
    let errorMsg = error;

    if (typeof errorMsg === 'undefined') {
        message = "Something went wrong, Please try again.";
    }
    else if (typeof errorMsg === 'object') {

        if (typeof errorMsg.message !== 'undefined') {
            message = errorMsg.message;
        }
        else if (typeof errorMsg.error !== 'undefined') {
            message = errorMsg.error;
        }
        else {
            message = "Something went wrong, please try again.";
        }
    }
    else {
        message = errorMsg;
    }

    return message;
}

function readResponse(response) {
    // console.log('response::::', response.data);
    return response.data;
}

// auth 
export const login = (params) => {
    return axios.post(`/login`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const register = (params) => {
    return axios.post(`/register`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const forgotPassword = (params) => {
    return axios.post(`/forgot-password`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getHomeData = () => {
    updateHeaders();
    return axios.get(`/home`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getStories = () => {
    updateHeaders();
    return axios.get(`/stories`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getFaqs = () => {
    updateHeaders();
    return axios.get(`/faqs`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const staticPage = (page_key) => {
    updateHeaders();
    return axios.get(`/static-page/${page_key}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const contactUS = (params) => {
    updateHeaders();
    return axios.post(`/contact-us`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const subscribe = (params) => {
    updateHeaders();
    return axios.post(`/subscribe-newsletter`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}
