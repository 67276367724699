import React, { useEffect, useState } from 'react';
import * as CustomValidators from '../../providers/shared/validator';
import * as GlobalProvider from '../../providers/globals/globals';
import * as GlobalConfig from '../../providers/globals/config';
import * as ApisService from "../../providers/apis/apis";
import { Link } from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Ellipsis } from "react-awesome-spinners";
import * as moment from 'moment';

function Login(props) {

  const [formData, setFormData] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [displayComponent, setDisplayComponent] = useState('login');
  const [open, setOpen] = useState(props.status);
  const [isLogin] = useState(false);
  const dateFormat = 'YYYY-MM-DD';
  const maxDate = moment().format(dateFormat);
  const minDate = moment().subtract(100, 'years').format(dateFormat);

  useEffect(() => {

    if (GlobalProvider.getToken()) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setDisplayComponent('book-now');
    }

    if (displayComponent === 'book-now') {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

  }, []);

  const onCloseModal = () => {

    setTimeout(() => {
      setOpen(false);
    }, 100);
  }

  const openOtherCompopent = (name) => {

    if (name === 'book-now') {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

    setErrors({}); // reset errors object
    setFormData({}); // reset formdata object
    setDisplayComponent(name);
  }

  const onFileSelection = (event) => {

    //console.log('file is selected')

    setTimeout(() => {

      setSelectedFile(event.target.files[0]);
      setIsFileSelected(true);
      setErrors({ ...errors, image: null });
    }, 100);
  };

  const handleInputChange = (event) => {

    const target = event.target;
    const value = target.value;
    const name = target.name;

    formData[name] = value;

    if (name === 'dob') {
      // update formdata field value 
      formData[name] = moment(formData.dob).format(dateFormat);
    }

    console.log('formData:::::', formData)

    setFormData(formData);
  }

  const validateLoginForm = (e) => {

    let errors = {};

    if (CustomValidators.isEmpty(formData.username)) {
      errors.username = "Email/Mobile can't be blank";
    }
    else if (CustomValidators.isContainWhiteSpace(formData.username)) {
      errors.username = "Email/Mobile should not contain white spaces";
    }
    else if (!CustomValidators.isLength(formData.username, { gte: 5, lte: 32, trim: true })) {
      errors.username = "Email/Mobile length must between 5 to 32";
    }
    // else if (!CustomValidators.isEmail(formData.username)) {
    //   errors.username = "Please enter a valid email";
    // }

    if (CustomValidators.isEmpty(formData.password)) {
      errors.password = "Password can't be blank";
    }
    else if (CustomValidators.isContainWhiteSpace(formData.password)) {
      errors.password = "Password should not contain white spaces";
    }
    else if (!CustomValidators.isLength(formData.password, { gte: 6, lte: 16, trim: true })) {
      errors.password = "Password's length must between 6 to 16";
    }

    if (CustomValidators.isEmpty(errors)) {
      return null;
    } else {
      return errors;
    }
  }

  const submitLogin = (e) => {

    e.preventDefault();

    setErrors({});
    setLoading(true);
    setFormSubmitted(true);

    // console.log('formData::::', formData)

    let errors = validateLoginForm();

    if (!errors) {

      ApisService.login(formData)
        .then(response => {

          // console.log('response::::', response)

          setLoading(false);
          setFormSubmitted(false);

          if (response.status) {

            if (response.data.status) {

              // store user information
              GlobalProvider.setToken(response.token);
              GlobalProvider.setUser(response.data);

              openOtherCompopent('book-now');
            } else {

              // display welcome message
              openOtherCompopent('welcome');
            }

          } else {

            setErrors({ message: response.message });
          }

        }).catch(error => {
          setErrors({ message: error });
          setLoading(false);
          setFormSubmitted(false);
        });

    } else {
      setErrors(errors);
      setLoading(false);
      setFormSubmitted(false);
    }
  }

  const validateForgotPasswordForm = (e) => {

    let errors = {};

    if (CustomValidators.isEmpty(formData.username)) {
      errors.username = "Email can't be blank";
    }
    else if (CustomValidators.isContainWhiteSpace(formData.username)) {
      errors.username = "Email should not contain white spaces";
    }
    else if (!CustomValidators.isLength(formData.username, { gte: 5, lte: 32, trim: true })) {
      errors.username = "Email's length must between 5 to 32";
    }
    else if (!CustomValidators.isEmail(formData.username)) {
      errors.username = "Please enter a valid email";
    }

    if (CustomValidators.isEmpty(errors)) {
      return null;
    } else {
      return errors;
    }
  }

  const submitForgotPassword = (e) => {

    e.preventDefault();

    setErrors({});
    setLoading(true);
    setFormSubmitted(true);

    // console.log('formData::::', formData)

    let errors = validateForgotPasswordForm();

    if (!errors) {

      ApisService.forgotPassword(formData)
        .then(response => {

          // console.log('response::::', response)

          setLoading(false);
          setFormSubmitted(false);

          if (response.status) {

            GlobalProvider.successMessage(response.message);
            openOtherCompopent('login');

          } else {

            setErrors({ message: response.message });
          }

        }).catch(error => {
          setErrors({ message: error });
          setLoading(false);
          setFormSubmitted(false);
        });

    } else {
      setErrors(errors);
      setLoading(false);
      setFormSubmitted(false);
    }
  }

  const validateRegisterForm = (e) => {

    let errors = {};

    if (CustomValidators.isEmpty(formData.name)) {
      errors.name = "First Name can't be blank";
    }

    if (CustomValidators.isEmpty(formData.last_name)) {
      errors.last_name = "Last Name can't be blank";
    }

    if (CustomValidators.isEmpty(formData.username)) {
      errors.username = "Email can't be blank";
    }
    else if (CustomValidators.isContainWhiteSpace(formData.username)) {
      errors.username = "Email should not contain white spaces";
    }
    else if (!CustomValidators.isLength(formData.username, { gte: 5, lte: 32, trim: true })) {
      errors.username = "Email's length must between 5 to 32";
    }
    else if (!CustomValidators.isEmail(formData.username)) {
      errors.username = "Please enter a valid email";
    }

    if (CustomValidators.isEmpty(formData.dob)) {
      errors.dob = "DOB can't be blank";
    }

    if (CustomValidators.isEmpty(formData.mobile)) {
      errors.mobile = "Mobile can't be blank";
    }
    else if (!CustomValidators.isPhoneNumber(formData.mobile)) {
      errors.mobile = "Please enter valid moile number";
    }

    if (CustomValidators.isEmpty(formData.password)) {
      errors.password = "Password can't be blank";
    }
    else if (CustomValidators.isContainWhiteSpace(formData.password)) {
      errors.password = "Password should not contain white spaces";
    }
    else if (!CustomValidators.isLength(formData.password, { gte: 6, lte: 16, trim: true })) {
      errors.password = "Password's length must between 6 to 16";
    }

    if (!CustomValidators.isMatchConfirmPassword(formData.password, formData.conf_password)) {
      errors.conf_password = "Password & Confirm Password should be same";
    }

    if (!isFileSelected) {
      errors.image = "Document File or Image is required";
    }

    if (CustomValidators.isEmpty(errors)) {
      return null;
    } else {
      return errors;
    }
  }

  const submitRegister = (e) => {

    e.preventDefault();

    setErrors({});
    setLoading(true);
    setFormSubmitted(true);

    // console.log('formData::::', formData)

    let errors = validateRegisterForm();

    if (!errors) {

      // append all information into form
      let formDataWithImage = new FormData();
      for (let [key, value] of Object.entries(formData)) {

        if (!['icon', 'image'].includes(key)) {
          formDataWithImage.append(key, value);
        }
      }
      formDataWithImage.append("image", selectedFile);

      ApisService.register(formDataWithImage)
        .then(response => {

          // console.log('response::::', response)

          setLoading(false);
          setFormSubmitted(false);

          if (response.status) {

            GlobalProvider.successMessage(response.message);
            openOtherCompopent('welcome');

          } else {

            setErrors({ message: response.message });
          }

        }).catch(error => {
          setErrors({ message: error });
          setLoading(false);
          setFormSubmitted(false);
        });

    } else {
      setErrors(errors);
      setLoading(false);
      setFormSubmitted(false);
    }
  }

  const Welcome = () => {

    return (
      <>
        <div className="card shadow-lg col-md-12">
          <div className="card-body ">
            <div className=" padding-box-2">
              <div className="p-b-20 text-center">
                <p>
                  <img src="images/logo-red-black.png" width="100" alt="" />
                </p>
              </div>
              <br />

              <div className="col-md-10 offset-md-1">
                <p>Thank you for registering for an online account. To complete your membership application please fill out our registration form using the link below.</p>
                <br />
                <a target="__blank" href="https://forms.finger-ink.com/chelsea-slimming/patient-intake-consent" className="text-underline" onClick={() => openOtherCompopent('login')}>https://forms.finger-ink.com/chelsea-slimming/patient-intake-consent</a>
                <br /><br />
                <p>Once received, our membership team will review your documents. Should your application be successful, you will receive an email containing your membership and online account details.</p>
                <br /><br />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const ForgotPassword = () => {

    return (
      <>
        <div className="card shadow-lg">
          <div className="card-body ">
            <div className=" padding-box-2">

              <div className="col-md-6 offset-md-3">

                <div className="p-b-20 text-center">
                  <p>
                    <img src="images/logo-red-black.png" width="100" alt="" />
                  </p>
                </div>

                <form className="needs-validation" onSubmit={submitForgotPassword} method="true">
                  <div className="form-row">
                    <div className="form-group floating-label col-md-12">
                      <label>Email</label>
                      <input name="username" type="text" placeholder="Email" className="form-control" defaultValue={formData.username} onChange={handleInputChange} />
                      {errors.username && <span className="error">{errors.username}</span>}
                    </div>
                  </div>

                  {errors.message && <><span className="error-response">{errors.message}</span></>}
                  <button type="submit" className="btn btn-primary btn-block btn-lg" disabled={loading}>{loading ? 'Loading...' : 'Submit'}</button>

                </form>

                <p>&nbsp;</p>

                <div className="form-row">
                  <div className="text-right p-t-10 col-md-6 offset-md-6">
                    <Link to={'#'} className="text-underline" onClick={() => openOtherCompopent('login')}>Login</Link>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </>
    )
  }

  const Login = () => {

    return (
      <>
        <div className="card shadow-lg col-md-12">
          <div className="card-body ">
            <div className=" padding-box-2">
              <div className="p-b-20 text-center">
                <p>
                  <img src="images/logo-red-black.png" width="100" alt="" />
                </p>
              </div>

              <div className="col-md-6 offset-md-3">

                <form className="needs-validation" onSubmit={submitLogin} method="true">
                  <div className="form-row">
                    <div className="form-group floating-label col-md-12">
                      <label>Email</label>
                      <input name="username" type="text" defaultValue={formData.username} placeholder="Email" className="form-control" onChange={handleInputChange} />
                      {errors.username && <span className="error">{errors.username}</span>}
                    </div>
                    <div className="form-group floating-label col-md-12">
                      <label>Password</label>
                      <input name="password" type="password" defaultValue={formData.password} placeholder="Password" className="form-control" onChange={handleInputChange} />
                      {errors.password && <span className="error">{errors.password}</span>}
                    </div>
                  </div>

                  {errors.message && <><span className="error-response">{errors.message}</span></>}
                  <button type="submit" className="btn btn-primary btn-block btn-lg" disabled={loading}>{loading ? 'Loading...' : 'Login'}</button>

                </form>

                <p>&nbsp;</p>

                <div className="form-row">
                  <div className="text-left p-t-10 col-md-6">
                    <Link to={'#'} className="text-underline" onClick={() => openOtherCompopent('signup')}>Signup</Link>
                  </div>
                  <div className="text-right p-t-10 col-md-6">
                    <Link to={'#'} className="text-underline" onClick={() => openOtherCompopent('forgot-password')}>Forgot Password?</Link>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </>
    )
  }

  const Login_new = () => {

    return (
      <>
        <div className="modal-content m-t-80">
          <div className="modal-body">

            <div className="card shadow-lg col-md-12">
              <div className="card-body ">
                <div className=" padding-box-2">
                  <div className="p-b-20 text-center">
                    <p>
                      <img src="images/logo-red-black.png" width="100" alt="" />
                    </p>
                  </div>

                  <form className="needs-validation" method="true">
                    <div className="form-row">
                      <div className="form-group floating-label col-md-12">
                        <label>Email</label>
                        <input name="username" type="text" placeholder="Email" className="form-control" />
                        <span className="error">error messages goes here...</span>
                      </div>
                      <div className="form-group floating-label col-md-12">
                        <label>Password</label>
                        <input name="password" type="password" placeholder="Password" className="form-control" />
                        <span className="error">error messages goes here...</span>
                      </div>
                    </div>

                    <span className="error-response">API error response</span>
                    <button type="submit" className="btn btn-primary btn-block btn-lg">Login</button>

                  </form>

                  <p>&nbsp;</p>

                  <div className="form-row">
                    <div className="text-left p-t-10 col-md-6">
                      {/* <a href='#' className="text-underline" >Signup</a> */}
                      <Link to={'#'} className="text-underline" onClick={() => openOtherCompopent('signup')}>Signup</Link>
                    </div>
                    <div className="text-right p-t-10 col-md-6">
                      {/* <a href='#' className="text-underline" >Forgot Password?</a> */}
                      <Link to={'#'} className="text-underline" onClick={() => openOtherCompopent('forgot-password')}>Forgot Password?</Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }

  const Signup = () => {

    return (
      <>
        <div className="card shadow-lg col-md-12">
          <div className="card-body ">
            <div className=" padding-box-2">
              <div className="p-b-20 text-center">
                <p>
                  <img src="images/logo-red-black.png" width="100" alt="" />
                </p>
              </div>

              <form className="needs-validation" onSubmit={submitRegister} method="true">
                <div className="form-row">
                  <div className="form-group floating-label col-md-6">
                    <label>First Name</label>
                    <input name="name" type="text" placeholder="First Name" defaultValue={formData.name} className="form-control" onChange={handleInputChange} />
                    {errors.name && <span className="error">{errors.name}</span>}
                  </div>
                  <div className="form-group floating-label col-md-6">
                    <label>Last Name</label>
                    <input name="last_name" type="text" placeholder="Last Name" defaultValue={formData.last_name} className="form-control" onChange={handleInputChange} />
                    {errors.last_name && <span className="error">{errors.last_name}</span>}
                  </div>
                  <div className="form-group floating-label col-md-6">
                    <label>Mobile</label>
                    <input name="mobile" type="phone" defaultValue={formData.mobile} placeholder="Mobile" className="form-control" onChange={handleInputChange} />
                    {errors.mobile && <span className="error">{errors.mobile}</span>}
                  </div>
                  <div className="form-group floating-label col-md-6">
                    <label>Date of Birth</label>
                    <input name="dob" type="date" defaultValue={formData.dob} placeholder="DOB" min={minDate} max={maxDate} className="form-control" onChange={handleInputChange} />
                    {errors.dob && <span className="error">{errors.dob}</span>}
                  </div>
                  <div className="form-group floating-label col-md-6">
                    <label>Email</label>
                    <input name="username" type="email" placeholder="Email" className="form-control" defaultValue={formData.username} onChange={handleInputChange} />
                    {errors.username && <span className="error">{errors.username}</span>}
                  </div>
                  <div className="form-group floating-label col-md-6">
                    <label>Password</label>
                    <input name="password" type="password" placeholder="Password" defaultValue={formData.password} className="form-control" onChange={handleInputChange} />
                    {errors.password && <span className="error">{errors.password}</span>}
                  </div>
                  <div className="form-group floating-label col-md-6">
                    <label>Upload Document ID </label>
                    <input type="file" className="form-control" name="image" onChange={(e) => onFileSelection(e)} />
                    {errors.image && <span className="error">{errors.image}<br /></span>}
                    {selectedFile && <small>{selectedFile.name}</small>}
                  </div>
                  <div className="form-group floating-label col-md-6">
                    <label>Confirm Password</label>
                    <input name="conf_password" type="password" placeholder="Confirm Password" defaultValue={formData.conf_password} className="form-control" onChange={handleInputChange} />
                    {errors.conf_password && <span className="error">{errors.conf_password}</span>}
                  </div>
                  <div className="form-group floating-label col-md-12">
                    <label><smal className="small-txt">We are required to verify your identity before we can prescribe slimming medications. Please upload a copy of your government issued ID document such as your passport or driving license. Please ensure the image shows all four corners of the document and the text is clearly visible</smal> </label>
                  </div>
                </div>

                <div className="col-md-6 offset-md-3">
                  {errors.message && <><span className="error-response">{errors.message}</span></>}
                  <button type="submit" className="btn btn-primary btn-block btn-lg" disabled={loading}>{loading ? 'Loading...' : 'Register'}</button>
                </div>

              </form>

              <p>&nbsp;</p>

              <div className="form-row">
                <div className="text-left p-t-10 col-md-6">
                  <Link to={'#'} className="text-underline" onClick={() => openOtherCompopent('login')}>Login</Link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }

  const BookNow = () => {

    return (
      <>
        { loading && <div className="center"><Ellipsis /></div>}

        { !loading && <iframe id='cliniko-66326085' src={GlobalConfig.bookingURL} frameBorder='0' scrolling='auto' width='100%' height='500' title="book now" style={{ pointerEvents: 'auto' }}></iframe>}
      </>
    )
  }

  return (
    <>
      <Modal open={open} onClose={onCloseModal} center>

        <div className="modal-content m-t-80">
          <div className="modal-body">

            {displayComponent === 'login' && <Login />}

            {displayComponent === 'book-now' && <BookNow />}

            {displayComponent === 'welcome' && <Welcome />}

            {displayComponent === 'signup' && <Signup />}

            {displayComponent === 'forgot-password' && <ForgotPassword />}

          </div>
        </div>

      </Modal>
    </>
  )
}

export default Login;