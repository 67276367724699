import React, { useEffect, useState } from "react";
import * as GlobalProvider from '../../providers/globals/globals';
import * as ApisService from "../../providers/apis/apis";
import Header from '../../components/elements/Header';
import Footer from '../../components/elements/Footer';

function FAQs(props) {

    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {

        GlobalProvider.setLoader(true);

        ApisService.getFaqs()
            .then(response => {

                GlobalProvider.setLoader(false);
                
                if (response.status) {
                    setFaqs(response.data);
                } else {
                    GlobalProvider.errorMessage(response.message);
                }

            }).catch(error => {
                GlobalProvider.setLoader(false);
                GlobalProvider.errorMessage(error);
            });
    }

    return (

        <>
            <Header activeClass="faqs" />

            <section className="section" id="faq-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <br /><br />
                            <div className="section-heading">
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {faqs.map((item, index) =>
                            <div key={'list-' + index} className="col-lg-12">
                                <div className="trainer-item">
                                    <div className="down-content">
                                        <h4>{item.question}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                                    </div>
                                </div>
                                <br />
                            </div>
                        )}

                    </div>
                </div>
            </section>

            <Footer />
        </>

    );
}

export default FAQs;