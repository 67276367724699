import React from 'react';
import { Link } from "react-router-dom";
import * as GlobalConfig from '../../providers/globals/config';
import * as GlobalProvider from '../../providers/globals/globals';

function Banner(props) {

    const gotoChoseProgram = () => {
        GlobalProvider.gotoChoseProgram();
    }

    return (

        <div className="main-banner" id="top">

            <video autoPlay muted loop id="bg-video">
                <source src={GlobalConfig.videoURL} type="video/mp4" />
            </video>

            <div className="video-overlay header-text">
                <div className="caption">
                    <h6>{props.data.banner_text_1}</h6>
                    <h2>{props.data.banner_text_2} <em> {props.data.banner_text_2_red}</em></h2>
                    <div className="main-button scroll-to-section">
                        <Link to={'/'} onClick={gotoChoseProgram}>Learn More</Link>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Banner;