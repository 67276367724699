import React, { useState } from 'react';
import * as GlobalProvider from '../../providers/globals/globals';
import * as ApisService from "../../providers/apis/apis";
import * as CustomValidators from '../../providers/shared/validator';

function Contact(props) {

    const [formData, setFormData] = useState({ status: 1 });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {

        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        formData[name] = value;

        setFormData(formData);
    }

    const validateLoginForm = (e) => {

        let errors = {};

        if (CustomValidators.isEmpty(formData.name)) {
            errors.name = "Name can't be blank";
        }

        if (CustomValidators.isEmpty(formData.email)) {
            errors.email = "Email can't be blank";
        }
        else if (!CustomValidators.isEmail(formData.email)) {
            errors.email = "Please provide a valid email";
        }

        if (CustomValidators.isEmpty(formData.subject)) {
            errors.subject = "Subject can't be blank";
        }

        if (CustomValidators.isEmpty(formData.message)) {
            errors.message = "Message can't be blank";
        }

        if (CustomValidators.isEmpty(errors)) {
            return null;
        } else {
            return errors;
        }
    }

    const contact_us = (e) => {

        e.preventDefault();

        setErrors({});
        setLoading(true);

        let errors = validateLoginForm();

        if (!errors) {

            ApisService.contactUS(formData)
                .then(response => {

                    if (response.status) {

                        GlobalProvider.successMessage(response.message);

                        setLoading(false);
                        document.getElementById("contact").reset();
                    } else {
                        setLoading(false);
                        GlobalProvider.errorMessage(response.message);
                    }

                }).catch(error => {
                    setLoading(false);
                    GlobalProvider.errorMessage(error);
                });

        } else {
            setErrors(errors);
            setLoading(false);
        }
    }

    return (

        <section className="section" id="contact-us">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-heading">
                            {/* <h2>Contact <em>US</em></h2> */}
                            {/* <img src="images/line-dec.png" alt="" /> */}
                            <p>{props.text}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    {/* <div className="col-lg-6 col-md-6 col-xs-12">
                        <div id="map">
                            <iframe
                                title="map view"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9937.564809275402!2d-0.17785780427328596!3d51.4876882354689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876056c23490e4f%3A0x268033680c352ea!2sChelsea%2C%20London%2C%20UK!5e0!3m2!1sen!2sin!4v1627563084983!5m2!1sen!2sin"
                                width="100%" height="600" frameBorder="0" style={{ 'border': 0 }} allowFullScreen=""
                                loading="lazy"></iframe>
                        </div>
                    </div> */}
                    <div className="col-lg-3 col-md-6 col-xs-12">&nbsp;</div>
                    <div className="col-lg-6 col-md-6 col-xs-12">
                        <div className="contact-form">

                            <form id="contact" className="needs-validation" onSubmit={contact_us} method="true">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 margin-bot-30">
                                        <fieldset>
                                            <input name="name" type="text" id="name" className="form-control" placeholder="Your Name*" onKeyUp={handleChange} required />
                                            {errors.name && <span className="error">{errors.name}</span>}
                                        </fieldset>
                                    </div>
                                    <div className="col-md-6 col-sm-12 margin-bot-30">
                                        <fieldset>
                                            <input name="email" type="text" id="email" pattern="[^ @]*@[^ @]*" placeholder="Your Email*" onKeyUp={handleChange} required />
                                            {errors.email && <span className="error">{errors.email}</span>}
                                        </fieldset>
                                    </div>
                                    <div className="col-md-12 col-sm-12 margin-bot-30">
                                        <fieldset>
                                            <input name="subject" type="text" id="subject" placeholder="Subject*" onKeyUp={handleChange} required />
                                            {errors.subject && <span className="error">{errors.subject}</span>}
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-12 margin-bot-30">
                                        <fieldset>
                                            <textarea name="message" rows="6" id="message" placeholder="Message*" onKeyUp={handleChange} required></textarea>
                                            {errors.message && <span className="error">{errors.message}</span>}
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <button type="submit" id="form-submit" className="main-button" disabled={loading}>{loading ? 'Waiting...' : 'Send Message'}</button>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Contact;