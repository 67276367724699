import React, { useEffect, useState } from "react";
import * as GlobalProvider from '../../providers/globals/globals';
import * as ApisService from "../../providers/apis/apis";
import Header from '../../components/elements/Header';
import Footer from '../../components/elements/Footer';

function StaticPage(props) {

    const [pageKey, setPageKey] = useState(props.match.path.replace('/', ''));
    const [pageContect, setPageContent] = useState([]);

    useEffect(() => {
        loadData(pageKey);
    }, []);

    useEffect(() => {

        let page_key = props.match.path.replace('/', '');
        if(pageKey !== page_key && pageKey !== '') {
            setPageKey(page_key);
            loadData(page_key);
        }
    });

    const loadData = (page_key) => {

        GlobalProvider.setLoader(true);

        ApisService.staticPage(page_key)
            .then(response => {

                GlobalProvider.setLoader(false);

                if (response.status) {
                    setPageContent(response.data);
                } else {
                    GlobalProvider.errorMessage(response.message);
                }

            }).catch(error => {
                GlobalProvider.setLoader(false);
                GlobalProvider.errorMessage(error);
            });
    }

    return (

        <>
            <Header activeClass={pageKey} />

            <section className="section" id="trainers">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <br /><br />
                            <div className="section-heading">
                                {/* <h2>Success <em>Stories</em></h2> */}
                                {/* <img src="images/line-dec.png" alt="" /> */}
                                {/* <p>&nbsp;</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="static-page-content other-conditions">

                            <div dangerouslySetInnerHTML={{ __html: pageContect.description }} />
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </>

    );
}

export default StaticPage;