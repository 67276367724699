import React from 'react';

function Program(props) {

    return (

        <section className="section" id="features">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 offset-lg-0">
                        <div className="section-heading">
                            <h2>Why <em>The Albany Clinic</em></h2>
                            <img src="images/line-dec.png" alt="waves" />
                            <p>{props.text}</p>
                        </div>
                    </div>

                    {props.list.map((item, outer_index) =>
                        <div key={'outer-program-box' + outer_index} className="col-lg-6">
                            <ul className="features-items">
                                {item.map((program, index) =>
                                <li key={'program-' + index} className="feature-item">
                                    <div className="left-icon">
                                        <img src={program.icon} alt={program.title} />
                                    </div>
                                    <div className="right-content">
                                        <h4>{program.title}</h4>
                                        <p>{program.message}</p>
                                    </div>
                                </li>
                                )}
                            </ul>
                        </div>
                    )}

                </div>
            </div>
        </section>

    );
}

export default Program;