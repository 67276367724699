import React from 'react';
import * as GlobalProvider from '../../providers/globals/globals';
import WeightCounter from '../../components/elements/WeightCounter';

function Stories(props) {

    const counterStart = () => {
        return GlobalProvider.counterInitialValue();
    }

    return (

        <section className="section" id="trainers">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-heading">
                            <h2>Success <em>Stories</em></h2>
                            <img src="images/line-dec.png" alt="" />
                            <p>{props.text}</p>
                        </div>
                        {/* <div className="center pad-bot-20">
                            <p>So far our clients have lost</p>
                            <WeightCounter start={counterStart()} />
                        </div> */}
                    </div>
                </div>
                <div className="row">

                    {props.list.map((item, index) =>
                        <div key={'story-' + index} className="col-lg-4">
                            <div className="trainer-item">
                                <div className="image-thumb">
                                    <img src={item.image} alt="" />
                                </div>
                                <div className="down-content">

                                    {/* <WeightCounter start={counterStart()} /> */}

                                    <span>{item.customer_name}</span>
                                    {/* <h4>{item.title}</h4> */}
                                    <p>{item.message}</p>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </section>

    );
}

export default Stories;