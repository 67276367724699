import React, { useEffect, useState } from 'react';
import * as GlobalProvider from '../../providers/globals/globals';
import { Link } from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

function Cookie(props) {

  const [open, setOpen] = useState(props.status);
  const [isHideCls, setIsHideCls] = useState((GlobalProvider.getCookie()) ? 'hide' : '');

  useEffect(() => {

  }, []);

  const onCloseModal = () => {

    setIsHideCls('hide');
    
    setTimeout(() => {
      setOpen(false);
    }, 100);
  }

  const acceptCookie = () => {

    GlobalProvider.setCookie(true);
    setOpen(false);
    setIsHideCls('hide');
  }

  return (
    <>
      <div className={'padding-tot-50 ' + isHideCls}></div>

      <div className={'cookies-all ' + isHideCls}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cookies">
                <div className="cookies-text">
                  <p>This website uses cookies to give you the best browsing experience.</p>
                </div>
                <div className="cookies-button">
                  <button type="button" className="accept m-button" onClick={() => acceptCookie()}>Accept</button>
                  <button type="button" className="close m-button" onClick={() => onCloseModal()}>Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <Modal open={open} onClose={onCloseModal} center>

        <div className="modal-content m-t-80">
          <div className="modal-body">

            <div className="card shadow-lg">
              <div className="card-body ">
                <div className=" padding-box-2">

                  <div className="col-md-8 offset-md-2">

                    <h4>This website uses cookies</h4>
                    <br />

                    <p>We use cookies to personalise content and ads, to provide social media features and to analyse our traffic.</p>
                    <p>We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.</p>

                    <br /><br />

                    <button type="button" className=" btn btn-primary btn-block btn-lg col-md-3" onClick={() => acceptCookie()}>Accept</button>

                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

      </Modal> */}
    </>
  )
}

export default Cookie;